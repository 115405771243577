<template>
  <li>
    <item-card :url="`/catalog_item_units/${item.id}`" contentClass="grid grid-cols-12">
      <template v-slot:title>
        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300 mb-2 md:mb-0">
          {{ item.catalog_item.name }}
        </h3>
      </template>

      <template v-slot:content>

        <div class="col-span-12 sm:col-span-6 lg:col-span-2">
          <item-text title="Piegādātājs" :text="item.supplier.name" />
        </div>

        <div class="col-span-12 sm:col-span-6 lg:col-span-2">
          <item-text title="Cena" :text="item.procurement_price" />
        </div>

        <div class="col-span-12 sm:col-span-6 lg:col-span-2">
          <div class="flex items-center">
            <item-text title="IMEI" :text="item.number" />
            <Button icon="copy" tooltip="Kopēt kodu" @click="copyText(item.number)" className="px-1 py-1 mt-3 -ml-2" />
          </div>
        </div>
      
        <template v-if="item.return_act">
          <div class="col-span-12 sm:col-span-6 lg:col-span-2">
            <div class="flex items-center pl-8 h-full">
              <Badge text="Atgriezts" className="bg-red-400 text-red-900" />
            </div>
          </div>
        </template>
      </template>
    </item-card>
  </li>
</template>

<script>
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"
import Badge from "@/components/Components/Badge"

export default {
  name: "CatalogItemUnitListItem",
  components: {
    ItemCard,
    ItemText,
    Badge,
  },
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  methods: {
      copyText(text) {
          var dummy = document.createElement("textarea");
          // to avoid breaking orgain page when copying more words
          // cant copy when adding below this code
          // dummy.style.display = 'none'
          document.body.appendChild(dummy);
          //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
          dummy.value = text;
          dummy.select();
          document.execCommand("copy");
          document.body.removeChild(dummy);
      },
  }
}
</script>

<style>
</style>